@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TT Norms Pro';
  font-style: normal;
  font-weight: 500;
  src: url('../public/fonts/TypeType - TT Norms Pro Regular.ttf');
}

@layer utilities {
  @variants responsive {
    .masonry {
      column-gap: 1.5em;
      column-count: 1;
    }

    .masonry-sm {
      column-gap: 1.5em;
      column-count: 2;
    }

    .masonry-md {
      column-gap: 1.5em;
      column-count: 3;
    }

    .break-inside {
      break-inside: avoid;
    }

  }
}

body {
  font-family: 'TT Norms Pro';
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container-8A {
  margin: 5px auto;
  overflow: auto;
  padding: 10px;
}

.container-form-8A {
  max-width: 1000px;
  min-width: 550px;
  margin: 30px auto;
  overflow: auto;
  /* min-height: 300px; */
  /* border: 1px solid steelblue; */
  padding: 30px;
  border-radius: 2rem;
  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

}

input {
  outline-color:#007aff;
}


.header-8A {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
  font-size: 22px;
  color: #00518C;
}

.btn-8A {
  display: inline-block;
  background: #00A1A8;
  color: #fff;
  border: none;
  padding: 2px 8px;
  margin: 3px;
  border-radius: 1rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn-8A:focus {
  outline: none;
}

.btn-8A:active {
  transform: scale(0.98);
}

.btn-block-8A {
  display: block;
  width: 100%;
}

.add-form-8A {
  font-family: inherit;
  /* margin-bottom: 40px; */
}

.form-control-8A {
  margin: 20px 0;
  font-family: inherit;
}

.form-control-8A label {
  display: block;
  font-family: inherit;
}

.form-control-8A input {
  width: 100%;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 3px 7px;
  font-size: 17px;
  font-family: inherit;
  border-radius: 2rem;
  box-shadow: 0 4px 6px -1px #e2e8f0, 0 2px 4px -2px #e2e8f0;
  /* border-color: #D9E9EC;
  border: 1px;
  border-style: solid; */
  border: #D9E9EC 1px solid
}

.form-control-no-margin-8A input {
    width: 100%;
    height: 40px;
    padding: 3px 7px;
    font-size: 17px;
    font-family: inherit;
    border-radius: 2rem;
    box-shadow: 0 4px 6px -1px #e2e8f0, 0 2px 4px -2px #e2e8f0;
    /* border-color: #D9E9EC;
    border: 1px;
    border-style: solid; */
    border: #D9E9EC 1px solid
  }

::placeholder {
  font-style: italic;
}

select {
  border: #D9E9EC 1px solid;
  background-color: white;
  /* outline-color: rgb(0, 161, 168); */
}


.form-control-8A select {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
  font-family: inherit;
  box-shadow: 0 4px 6px -1px #e2e8f0, 0 2px 4px -2px #e2e8f0;
  outline-color:#007aff;
  border-radius: 2rem;
}

.objects-8A {
  font-family: 'TT Norms Pro';
  border-collapse: collapse;
  width: 100%;
}

/* .objects-8A td, .objects-8A th {
  border: 1px solid #ddd;
  padding: 8px;
} */

.objects-8A tr:nth-child(even) {
  background-color: #f2f2f24d;
}

.objects-8A tr:nth-child(odd){background-color: #f2f2f2ab;}

.objects-8A tr:hover {
  background-color: rgb(241 245 249);
}

/* .objects-8A th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: rgb(0, 144, 202);
  color: white;
} */

.objects-highlighted-td-8A {
  border: 1px solid rgb(226 232 240);
  padding: 8px;
  background-color: rgb(235, 134, 134);
}


.logo-8A {
  width: 120px;
  height: 100px;
  padding: 10px;
  float: right;
}

.viewObjectField-8A {
  padding: 5px;
  font-weight: bold
}

.viewObjectDetailsBox-8A {
  float: left;
  padding: 0% 2% 0% 0%;
}

.sdg-8A {
  padding: 2%;
  height: 50px;
  width: 50px;
  float: left
}

.leaflet-container {
  width: 70%;
  height: 50vh;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 10px;
}

.chart-holder-8A {
  height: 40%;
  width: 40%;
  float: left;
  margin: 0% 10% 0% 0%
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"]+label {
  display: inline-block;
  background: #0090CA;
  color: #fff;
  border: none;
  padding: 3px 8px;
  margin: 3px;
  border-radius: 1rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 17px;
  font-family: inherit;
  white-space: nowrap;
  box-shadow: 2px 2px 5px lightgrey;
}


.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0; */
  /* transition: all 0.3s ease-in-out; */
  /* pointer-events: none; */
}

.modal-content {
  width: 95%;
  background-color: #fff;
  /* transition: all 0.3s ease-in-out;
  transform: translateY(-200px); */
  height: 75%;
  overflow-y: scroll;
}

.modal-header,
.modal-footer {
  padding: 10px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  /* padding: 10px; */
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.update-value-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgb(203 213 225 / 0.3); */
  background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
  /* backdrop-filter: blur(8px); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0; */
  /* transition: all 0.3s ease-in-out; */
  /* pointer-events: none; */
}

.update-value-modal-content {
  background-color: #fff;
  /* transition: all 0.3s ease-in-out;
    transform: translateY(-200px); */
  border-radius: 15px;
  /* overflow-y: scroll; */
  align-items: center;
  justify-content: center;
  border: #D9E9EC 1px solid;
  /* box-shadow: 0 4px 6px -1px #e2e8f0, 0 2px 4px -2px #e2e8f0; */
  width: 100%;
  max-width: 600px;
}

.update-value-modal-timesheet-content {
  background-color: #fff;
  /* transition: all 0.3s ease-in-out;
    transform: translateY(-200px); */
  border-radius: 15px;
  /* overflow-y: scroll; */
  align-items: center;
  justify-content: center;
  border: #D9E9EC 1px solid;
  /* box-shadow: 0 4px 6px -1px #e2e8f0, 0 2px 4px -2px #e2e8f0; */
  width: 100%;
  max-width: 1000px


}

.update-value-modal-header,
.update-value-modal-footer {
  padding: 10px;
}

.update-value-modal-title {
  margin: 0;
}

.update-value-modal-body {
  padding: 10px;
}

/* / */

.loading-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  /* backdrop-filter: blur(8px); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0; */
  /* transition: all 0.3s ease-in-out; */
  /* pointer-events: none; */
}

.loading-modal-content {
  background-color: #fff;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  border: #D9E9EC 1px solid;
  /* box-shadow: 0 4px 6px -1px #e2e8f0, 0 2px 4px -2px #e2e8f0; */
  width: 100%;
  max-width: 300px;
  height: 300px;
}

.loading-modal-header,
.loading-modal-footer {
  padding: 10px;
}

.loading-modal-title {
  margin: 0;
  padding-top:20px;
  text-align: center;
}

.loading-modal-body {
  padding-top: 50px;
  display: block;
  /* display: block;
  margin-left: auto;
  margin-right: auto; */
}

/* / */

#showcase2-8A {
  background-image: url(/public/images/showcase2.jpg);
  background-position: center;
  height: 300px;
  margin-bottom: 30px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

#showcase-8A {
  background-image: url(/public/images/showcase.jpg);
  background-position: center;
  height: 300px;
  margin-bottom: 30px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.gif-8A {
  width: 220px;
  height: 150px;
  padding: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-image: url(/public/images/cogstill.gif);
  transition: 0s 3s
}

.gif-8A:active {
  width: 220px;
  height: 150px;
  padding: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-image: url(/public/images/cog.gif);
  transition: 0s;
  border: 5px solid rgb(0, 161, 168)
}

.items-holder-8A {
  max-height: 75vh;
  min-height: 50vh;
  max-width: 100%;
}

.tooltip {
    position: absolute;
    display: inline-block;
    color: white;
    border-radius: 5px;
    padding: 10px;
  }

  .camera-modal {
    /* position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; */
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0; */
    /* transition: all 0.3s ease-in-out; */
    /* pointer-events: none; */
  }
