.service-total-row {
    background-color: #f6f6f6;
    font-size: 12px;
    font-family: 'TT Norms Pro';
    overflow: hidden;
}

.service-total-row:hover {
    /* background-color: #e1edf1 !important; */
    background-color: #f6f6f6;
}

.service-row {
    background-color: #f6f6f6;
    font-size: 12px;
    font-family: 'TT Norms Pro';
    overflow: hidden;
}

.service-row>td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.service-row:hover {
    background-color: #f6f6f6 !important;
}

.activity-row {
    background-color: #f6f6f6;
    font-size: 12px;
    font-family: 'TT Norms Pro';
}

.activity-row>td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.activity-row:hover {
    background-color: #f6f6f6 !important;
}

.ant-table-thead>tr>th {
    /* background-color: #B0D2D8 !important; */
    background-color: #e1edf1 !important;
    color: #00518c !important;
    font-size: 12px;
    font-family: 'TT Norms Pro';
    text-transform: uppercase;
    font-weight: normal !important;
    padding: 6px 7px 4px 7px !important;
}